<template>
  <div class="input__wrapper" :class="inputWrapperClass">
    <transition name="fade-spinner" mode="out-in">
      <div v-if="!isPrefilled" class="input__component-wrapper">
        <input
          v-if="!multiline"
          ref="input"
          :tabindex="inputTabIndex"
          :value="value"
          :type="inputType"
          :aria-label="ariaLabel || placeholder"
          :class="inputClass"
          :maxlength="maxLength"
          @click="clickHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keypress="keypressHandler"
          @keyup="$emit('keyup', $event)"
          @input="$emit('input', $event.target.value)"
          @keydown="$emit('keydown', $event)"
        />
        <textarea
          v-else
          ref="input"
          :tabindex="inputTabIndex"
          :value="value"
          :rows="rows"
          :aria-label="ariaLabel || placeholder"
          class="input__textarea"
          :class="inputClass"
          :maxlength="maxLength"
          @click="clickHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keypress="keypressHandler"
          @keyup="$emit('keyup', $event)"
          @input="$emit('input', $event.target.value)"
          @keydown="$emit('keydown', $event)"
        />
        <span
          v-if="label && !hideLabel"
          class="input__label"
          :class="labelClass"
        >
          {{ label }}
        </span>
        <span class="input__placeholder" :class="placeholderClass">
          {{ placeholder }}
        </span>
        <a-spinner
          v-if="inProgress"
          disable-transition
          :size="20"
          :stroke="5"
          class="input__icon"
        />
        <a-icon
          v-else-if="isIconVisible"
          v-bind="dynamicIconSettings"
          :is-button="!clearable"
          :no-hover="clearable"
          :width="23"
          :height="14"
          class="input__icon input__icon-action"
          @click="onIconClick"
          @blur="onIconBlur"
        />
        <i
          v-else-if="isClearButtonVisible"
          v-focusable
          class="close-icon close-icon_sm"
          @click="$emit('input', '')"
        />
      </div>
      <div v-else :class="preFilledWrapperClass">
        <a-icon
          :icon="ICON.PENCIL"
          :width="19"
          :height="19"
          is-button
          v-bind="dynamicIconSettings"
          class="input__prefilled-icon"
          @click="exitPrefilledMode($event)"
        />
        <div class="input__prefilled-content">
          <div class="input__prefilled-label">{{ label || placeholder }}</div>
          <div class="input__prefilled-value">{{ value }}</div>
        </div>
      </div>
    </transition>
    <a-validation-message :error="inputError" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import mixins from '@/utils/mixins'
import {
  propValidator,
  generalValidation,
  PROP_TYPES
} from '@fmpedia/validators'
import AIcon, { ICON } from 'shared/AIcon'
import ASpinner from 'shared/ASpinner'
import AValidationMessage from 'shared/AValidationMessage'

export const INPUT_STYLE = {
  /** Bordered input (for now is the most used input in the project) **/
  STYLE_1: 'STYLE_1',
  /** Non-bordered input **/
  STYLE_2: 'STYLE_2',
  /** Non-bordered underlined input (for now is used only in Ebook widget) **/
  STYLE_3: 'STYLE_3',
  /** Bordered input with specific height (for now is used only in Newsletter Sidebar widget) **/
  STYLE_4: 'STYLE_4'
}

const INPUT_STYLE_VALUES = Object.values(INPUT_STYLE)

export const INPUT_CLASS = 'input__input'

export const INPUT_TYPE = {
  PASSWORD: 'password',
  TEXT: 'text',
  EMAIL: 'email',
  URL: 'url',
  TEL: 'tel',
  NUMBER: 'number'
}

export const PRE_FILLED_BG_COLOR = {
  YELLOW: 'yellow'
}

const PRE_FILLED_CLASS = {
  [PRE_FILLED_BG_COLOR.YELLOW]: 'input__prefilled-wrapper_yellow'
}

export default {
  name: 'ATextInput',
  mixins: [mixins.validation],
  components: { AIcon, ASpinner, AValidationMessage },
  props: {
    value: propValidator([PROP_TYPES.STRING], false, ''),
    label: propValidator([PROP_TYPES.STRING], false),
    placeholder: propValidator([PROP_TYPES.STRING], false),
    multiline: propValidator([PROP_TYPES.BOOLEAN], false, false),
    disableGeneralValidation: propValidator([PROP_TYPES.BOOLEAN], false, false),
    rows: propValidator([PROP_TYPES.STRING, PROP_TYPES.NUMBER], false),
    required: propValidator([PROP_TYPES.BOOLEAN], false, false),
    prefilledMode: propValidator([PROP_TYPES.BOOLEAN], false, false),
    prefilledColor: propValidator([PROP_TYPES.STRING], false, null, prop =>
      Object.values(PRE_FILLED_BG_COLOR).includes(prop)
    ),
    error: propValidator([PROP_TYPES.OBJECT], false, () => ({})),
    type: propValidator([PROP_TYPES.STRING], false, INPUT_TYPE.TEXT, value =>
      Object.values(INPUT_TYPE).includes(value)
    ),
    passwordIcon: propValidator([PROP_TYPES.BOOLEAN], false, false),
    searchIcon: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hideLabel: propValidator([PROP_TYPES.BOOLEAN], false, false),
    maxLength: propValidator(
      [PROP_TYPES.STRING, PROP_TYPES.NUMBER],
      false,
      9999
    ),
    touchOnBlur: propValidator([PROP_TYPES.BOOLEAN], false, false),
    inputStyle: propValidator(
      [PROP_TYPES.STRING],
      false,
      INPUT_STYLE.STYLE_1,
      value => INPUT_STYLE_VALUES.includes(value)
    ),
    disabled: propValidator([PROP_TYPES.BOOLEAN], false, false),
    inProgress: propValidator([PROP_TYPES.BOOLEAN], false, false),
    blinkBorder: propValidator([PROP_TYPES.BOOLEAN], false, false),
    focusOnMount: propValidator([PROP_TYPES.BOOLEAN], false, false),
    clearable: propValidator([PROP_TYPES.BOOLEAN], false, false),
    ariaLabel: propValidator([PROP_TYPES.STRING], false)
  },
  data() {
    return {
      ICON,
      INPUT_TYPE,
      prefilledModeVal: this.value,
      isFocused: false,
      isPasswordShown: false
    }
  },
  validations: {
    value: {
      generalValidation
    }
  },
  computed: {
    ...mapGetters({
      isAppleBrowser: 'isAppleBrowser'
    }),
    inputTabIndex() {
      return this.disabled ? -1 : 0
    },
    isIconVisible() {
      const isPasswordWithIcon =
        this.type === INPUT_TYPE.PASSWORD && this.passwordIcon
      const isSearchIcon = this.searchIcon && (!this.clearable || !this.value)
      return isPasswordWithIcon || isSearchIcon
    },
    isClearButtonVisible() {
      return this.clearable && this.value
    },
    isPasswordInput() {
      return this.type === INPUT_TYPE.PASSWORD && this.passwordIcon
    },
    isSearchInput() {
      return !this.isPasswordInput && !!this.searchIcon
    },
    dynamicIconSettings() {
      if (this.isPrefilled) {
        return {
          icon: ICON.PENCIL,
          ariaLabel: 'Edit'
        }
      }

      if (this.isPasswordInput) {
        return {
          icon: ICON.EYE,
          ariaLabel: 'Show/Hide password'
        }
      }

      if (this.isSearchInput) {
        return {
          icon: ICON.MAGNIFIER,
          ariaLabel: 'Search'
        }
      }

      return {}
    },
    isInternalValidationPassed() {
      return this.disableGeneralValidation || !this.$v.value.$error
    },
    inputError() {
      return this.disableGeneralValidation
        ? this.error
        : this.error && this.error.$error
        ? this.error
        : this.$v.value
    },
    isPrefilled() {
      return this.prefilledMode && this.prefilledModeVal
    },
    inputWrapperClass() {
      return {
        input_focused: this.isFocused,
        'input_placeholder-as-label':
          (this.value && !this.label) || this.isFocused,
        input_disabled: this.disabled,
        input_multiline: this.multiline,
        'input_show-icon': this.isIconVisible || this.isClearButtonVisible,
        input_error: this.$_validation_hasValidationError(this.inputError),
        input_prefilled: this.isPrefilled,
        input_regular: this.inputStyle === INPUT_STYLE.STYLE_2,
        'input_apple-browser': this.isAppleBrowser,
        'input_style-3': this.inputStyle === INPUT_STYLE.STYLE_3,
        'input_style-4': this.inputStyle === INPUT_STYLE.STYLE_4,
        'blinking-border': this.blinkBorder
      }
    },
    inputClass() {
      return [
        INPUT_CLASS,
        { 'input_text-area': this.multiline },
        { error: this.$_validation_hasValidationError(this.inputError) },
        { focused: this.isFocused || this.value.length || this.hideLabel }
      ]
    },
    labelClass() {
      return {
        required: this.required
      }
    },
    placeholderClass() {
      return {
        error: this.$_validation_hasValidationError(this.inputError),
        focused: !this.label && (this.isFocused || this.value.length),
        /** Temporary commented, can be removed after full check **/
        // focused_safari:
        //   (this.isFocused || this.value.length) &&
        //   this.isAppleBrowser &&
        //   !this.label,
        hidden:
          (this.isFocused || this.value.length) &&
          (this.hideLabel || this.label),
        required:
          this.required &&
          ((this.value && !this.label) || this.isFocused || !this.label)
      }
    },
    inputType() {
      /**
       * maxlength attribute is ignored for type number.
       * That's why we use type text with additional attribute pattern="\d"
       * ToDo: pattern doesn't work properly. Will be implement when required.
       */
      if (this.type === INPUT_TYPE.NUMBER) return INPUT_TYPE.TEXT

      if (this.type !== INPUT_TYPE.PASSWORD) return this.type

      return !this.isPasswordShown ? INPUT_TYPE.PASSWORD : INPUT_TYPE.TEXT
    },
    preFilledWrapperClass() {
      return ['input__prefilled-wrapper', PRE_FILLED_CLASS[this.prefilledColor]]
    }
  },
  methods: {
    exitPrefilledMode(event) {
      this.prefilledModeVal = null
      this.$nextTick(this.focusInput)
    },
    clickHandler(e) {
      this.$emit('click', e)
    },
    focusHandler(e) {
      this.isFocused = true
      this.$emit('focus', e)
    },
    blurHandler(event) {
      this.isFocused = false
      if (this.touchOnBlur) {
        this.$v.$touch()
        if (this.error.$touch) {
          this.error.$touch()
        }
      }

      this.$emit('blur', event)
    },
    touchInnerValidation() {
      this.$v.$touch()
    },
    keypressHandler() {
      this.$emit('keypress', this.value)
    },
    onIconBlur(event) {
      this.$emit('blur:icon', event)
    },
    onIconClick() {
      const iconHandler = {
        [ICON.EYE]: this.onShowPassword,
        [ICON.MAGNIFIER]: this.onSearch
      }

      iconHandler[this.dynamicIconSettings.icon]()
    },
    onShowPassword() {
      this.isPasswordShown = !this.isPasswordShown
    },
    onSearch() {
      this.$emit('search', this.value)
    },
    focusInput() {
      if (this.$refs.input) {
        const unwatch = this.$watch(
          'isScrollInProgress',
          newVal => {
            if (newVal === false) {
              this.$refs.input.focus()

              this.$nextTick(unwatch)
            }
          },
          { immediate: true }
        )
      }
    }
  },
  mounted() {
    if (this.focusOnMount) {
      setTimeout(this.focusInput)
    }
  }
}
</script>

<style lang="scss" scoped>
.input__wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  background-color: $c--white;
  border: 2px solid $c--black;
  transition: border-color 150ms;

  .input__component-wrapper {
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    background-color: inherit;
  }

  .input__input {
    width: 100%;
    max-height: 100%;
    padding: 11px 15px;
    font-family: $font-sans;
    font-size: 16px;
    line-height: 22px;
    background: transparent;
    color: black;

    &::placeholder {
      color: #4a4a4a;
    }
  }

  .input__textarea {
    height: 100%;
  }

  $transform: transform 150ms ease-out, font-size 150ms ease-out, opacity 150ms,
    color 150ms ease-out;

  .input__placeholder,
  .input__label {
    position: absolute;
    left: 11px;
    top: 7px;
    padding: 0 4px;
    font-family: $font-sans;
    font-size: 16px;
    line-height: 22px;
    color: #4a4a4a;
    background-color: inherit;
    transition: $transform;
    pointer-events: none;

    &.hidden {
      opacity: 0;
    }
  }

  .input__placeholder {
    max-height: 22px;
    overflow: hidden;
  }

  .input__label {
    color: $c--black;
    font-size: 11px;
    line-height: 14px;
    transform: translateY(-130%);
  }

  .input__icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }

  &.input_placeholder-as-label .input__placeholder {
    transform: translateY(-130%);
    font-size: 11px;
    line-height: 14px;
  }

  &.input_show-icon .input__input {
    width: calc(100% - 15px);
  }

  &.input_focused .input__label,
  &.input_focused .input__placeholder {
    color: $c--main;
  }

  .input__input.input_text-area {
    resize: none;
  }

  &.input_focused {
    border-color: $c--main;
  }

  &.input_error {
    border-color: $c--error;
  }

  &.input_error .input__label {
    color: $c--error;
  }

  &.input_multiline {
    height: inherit;
    min-height: inherit;
    max-height: inherit;
  }

  &.input_disabled {
    pointer-events: none;
    background-color: $c--disabled;
    border-color: $c--disabled-border;

    .input__component-wrapper {
      background-color: $c--disabled;
    }

    .input__placeholder,
    .input__label,
    .input__input {
      color: $c--disabled-text;
    }

    .input__placeholder {
      background-color: $c--disabled;
    }
  }

  &.input_error.input_placeholder-as-label {
    .input__placeholder {
      color: $c--error;
    }
  }
}

.input__wrapper.input_prefilled {
  border: none;

  .input__prefilled-wrapper {
    display: flex;
    align-items: center;
    height: 40px;

    &_yellow {
      background-color: $c--never-miss-popup;
    }
  }

  .input__prefilled-icon {
    margin-right: 12px;
    cursor: pointer;
  }

  .input__prefilled-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .input__prefilled-label {
    font-family: $font-sans;
    font-size: 11px;
    line-height: 15px;
    color: #4a4a4a;
  }

  .input__prefilled-value {
    font-size: 16px;
  }
}

.input__wrapper.input_style-3 {
  border: none;
  background: transparent;
  padding: 0 0 5px 0;
  border-bottom: 2px solid rgba(#fff, 0.5);

  .input__input.focused {
    font-size: 14px;
    font-weight: 600;
    color: $c--white;
    padding: 11px 0;
  }

  .input__label,
  span.input__placeholder {
    left: 0;
    font-family: $font-sans;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: transparent;
  }

  &.input_error.input_placeholder-as-label {
    .input__placeholder {
      color: $c--white;
    }
  }

  &.input_error .input-group__error {
    color: $c--black;
  }
}

.input__wrapper.input_style-4 {
  height: 30px;
  padding: 0 15px;
  border: 1px solid $c--black;

  .input__input.focused {
    font-size: 12px;
    padding: 11px 0;
  }

  .input__label,
  span.input__placeholder {
    top: 3px;
    left: 11px;
    font-family: $font-sans;
    font-size: 12px;
    color: $c--gray-light-2;
  }

  &.input_error.input_placeholder-as-label {
    .input__placeholder {
      color: $c--white;
    }
  }

  &.input_error .input-group__error {
    color: $c--black;
  }
}

.input__wrapper.input_regular {
  border: none;
}
</style>
