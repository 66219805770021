export const DFP_BANNER = {
  TOP_SIDE_1140x250: {
    path: '/150277392/FMPedia_FL/FL_Desktop_Super_Leaderboard',
    sizes: [
      [1140, 90],
      [1100, 100],
      [980, 120],
      [980, 90],
      [970, 250],
      [970, 120],
      [970, 90],
      [950, 90],
      [930, 180],
      [750, 200],
      [750, 100],
      [728, 90],
      [728, 250],
      [728, 100]
    ],
    id: 'div-gpt-ad-Top_Side-1140x250'
  },
  TOP_SIDE_980x250: {
    path: '/150277392/FMPedia_FL/FL_Desktop_Super_Leaderboard',
    sizes: [
      [980, 120],
      [980, 90],
      [970, 250],
      [970, 120],
      [970, 90],
      [950, 90],
      [930, 180],
      [750, 200],
      [750, 100],
      [728, 90],
      [728, 250],
      [728, 100]
    ],
    id: 'div-gpt-ad-Top_Side-980x250'
  },
  TOP_SIDE_750x250: {
    path: '/150277392/FMPedia_FL/FL_Desktop_Super_Leaderboard',
    sizes: [
      [750, 200],
      [750, 100],
      [728, 90],
      [728, 250],
      [728, 100]
    ],
    id: 'div-gpt-ad-Top_Side-750x250'
  },
  TOP_SIDE_320x50: {
    path: '/150277392/FMPedia_FL/FL_Mobile_Super_Leaderboard',
    sizes: [
      [320, 50],
      [300, 50]
    ],
    id: 'div-gpt-ad-Top_Side-320x50'
  },
  RIGHT_SIDE_1_300x600: {
    path: '/150277392/FMPedia_FL/Super_Right_1',
    sizes: [
      [120, 600],
      [160, 600],
      [300, 600]
    ],
    id: 'div-gpt-ad-Super_Right_1-300x600'
  },
  RIGHT_SIDE_1_160x600: {
    path: '/150277392/FMPedia_FL/Super_Right_1',
    sizes: [
      [120, 600],
      [160, 600]
    ],
    id: 'div-gpt-ad-Super_Right_1-160x600'
  },
  RIGHT_SIDE_1_120x600: {
    path: '/150277392/FMPedia_FL/Super_Right_1',
    sizes: [[120, 600]],
    id: 'div-gpt-ad-Super_Right_1-120x600'
  },
  RIGHT_SIDE_2_300x600: {
    path: '/150277392/FMPedia_FL/Super_Right_2',
    sizes: [
      [120, 600],
      [160, 600],
      [300, 600]
    ],
    id: 'div-gpt-ad-Super_Right_2-300x600'
  },
  RIGHT_SIDE_2_160x600: {
    path: '/150277392/FMPedia_FL/Super_Right_2',
    sizes: [
      [120, 600],
      [160, 600]
    ],
    id: 'div-gpt-ad-Super_Right_2-160x600'
  },
  RIGHT_SIDE_2_120x600: {
    path: '/150277392/FMPedia_FL/Super_Right_2',
    sizes: [[120, 600]],
    id: 'div-gpt-ad-Super_Right_2-120x600'
  },
  LEADERBOARD_1_728x90: {
    path: '/150277392/FMPedia_FL/Leaderboard_1',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_1-728x90'
  },
  LEADERBOARD_1_468x60: {
    path: '/150277392/FMPedia_FL/Leaderboard_1',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_1-468x60'
  },
  LEADERBOARD_1_320x50: {
    path: '/150277392/FMPedia_FL/Leaderboard_1',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_1-320x50'
  },
  LEADERBOARD_2_728x90: {
    path: '/150277392/FMPedia_FL/Leaderboard_2',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_2-728x90'
  },
  LEADERBOARD_2_468x60: {
    path: '/150277392/FMPedia_FL/Leaderboard_2',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_2-468x60'
  },
  LEADERBOARD_2_320x50: {
    path: '/150277392/FMPedia_FL/Leaderboard_2',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_2-320x50'
  },
  LEADERBOARD_3_728x90: {
    path: '/150277392/FMPedia_FL/Leaderboard_3',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_3-728x90'
  },
  LEADERBOARD_3_468x60: {
    path: '/150277392/FMPedia_FL/Leaderboard_3',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_3-468x60'
  },
  LEADERBOARD_3_320x50: {
    path: '/150277392/FMPedia_FL/Leaderboard_3',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_3-320x50'
  },
  LEADERBOARD_4_728x90: {
    path: '/150277392/FMPedia_FL/Leaderboard_4',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_4-728x90'
  },
  LEADERBOARD_4_468x60: {
    path: '/150277392/FMPedia_FL/Leaderboard_4',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_4-468x60'
  },
  LEADERBOARD_4_320x50: {
    path: '/150277392/FMPedia_FL/Leaderboard_4',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_4-320x50'
  },
  LEADERBOARD_5_728x90: {
    path: '/150277392/FMPedia_FL/Leaderboard_5',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_5-728x90'
  },
  LEADERBOARD_5_468x60: {
    path: '/150277392/FMPedia_FL/Leaderboard_5',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_5-468x60'
  },
  LEADERBOARD_5_320x50: {
    path: '/150277392/FMPedia_FL/Leaderboard_5',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_5-320x50'
  },
  VIEW_MORE_728x90: {
    path: '/150277392/FMPedia_FL/View_More',
    sizes: [728, 90],
    id: 'div-gpt-ad-View_More-728x90'
  },
  VIEW_MORE_468x60: {
    path: '/150277392/FMPedia_FL/View_More',
    sizes: [468, 60],
    id: 'div-gpt-ad-View_More-468x60'
  },
  VIEW_MORE_320x50: {
    path: '/150277392/FMPedia_FL/View_More',
    sizes: [320, 50],
    id: 'div-gpt-ad-View_More-320x50'
  },
  MOBILE_STICKY_320x50: {
    path: '/150277392/FMPedia_FL/Mobile_Sticky',
    sizes: [320, 50],
    id: 'div-gpt-ad-Mobile_Sticky-320x50'
  },
  ARTICLE_MPU_300x250: {
    path: '/150277392/FMPedia_FL/Mobile_Article_MPU',
    sizes: [300, 250],
    id: 'div-gpt-ad-Mobile_Article_MPU-320x250'
  },
  MPU_1_300x250: {
    path: '/150277392/FMPedia_FL/MPU_1',
    sizes: [300, 250],
    id: 'div-gpt-ad-MPU_1-320x250'
  },
  MPU_2_300x250: {
    path: '/150277392/FMPedia_FL/MPU_2',
    sizes: [300, 250],
    id: 'div-gpt-ad-MPU_2-320x250'
  },
  DMPU_300x600: {
    path: '/150277392/FMPedia_FL/DMPU',
    sizes: [300, 600],
    id: 'div-gpt-ad-MPU_1-300x600'
  },
  START_TRADING_240x40: {
    path: '/150277392/FMPedia_FL/Start_Trading',
    sizes: [240, 40],
    id: 'div-gpt-ad-Start_Trading'
  },
  TRADE_THE_NEWS_160x40: {
    path: '/150277392/FMPedia_FL/Trade_the_News',
    sizes: [160, 40],
    id: 'div-gpt-ad-trade-the-news-160x40'
  },
  TRADE_THE_NEWS_290x40: {
    path: '/150277392/FMPedia_FL/Trade_the_News',
    sizes: [290, 40],
    id: 'div-gpt-ad-trade-the-news-290x40'
  },
  LEARN_TO_TRADE_318x40: {
    path: '/150277392/FMPedia_FL/Learn_to_Trade',
    sizes: [318, 40],
    id: 'div-gpt-ad-learn-to-trade_318x40'
  },
  AUTOMATED_LINK_290x88: {
    path: '/150277392/FMPedia_FL/TextAd',
    sizes: [290, 88],
    id: 'div-gpt-ad-TextAd_290x88'
  },
  AUTOMATED_LINK_553x54: {
    path: '/150277392/FMPedia_FL/TextAd',
    sizes: [553, 54],
    id: 'div-gpt-ad-TextAd_553x54'
  },
  AUTOMATED_LINK_584x54: {
    path: '/150277392/FMPedia_FL/TextAd',
    sizes: [584, 54],
    id: 'div-gpt-ad-TextAd_584x54'
  },
  AUTOMATED_LINK_658x54: {
    path: '/150277392/FMPedia_FL/TextAd',
    sizes: [658, 54],
    id: 'div-gpt-ad-TextAd_658x54'
  },
  AUTOMATED_LINK_768x54: {
    path: '/150277392/FMPedia_FL/TextAd',
    sizes: [768, 54],
    id: 'div-gpt-ad-TextAd_768x54'
  },
  ARTICLE_BELOW_CONTENT_FLUID: {
    path: '/150277392/FMPedia_FL/FL_BelowContent',
    sizes: ['fluid'],
    id: 'div-gpt-ad-1645534663863-0-fluid'
  },
  MPU_MIDDLE_ARTICLE_300x250: {
    path: '/150277392/FL_AMP_articles/MPU_middle_article',
    sizes: [300, 250],
    id: 'div-gpt-ad-MPU_middle_article-300x250'
  },
  MPU_BELOW_ARTICLE_300x250: {
    path: '/150277392/FL_AMP_articles/MPU_below_article',
    sizes: [300, 250],
    id: 'div-gpt-ad-MPU_below_article-300x250'
  },
  MOBILE_STICKY_BANNER_320x50: {
    path: '/150277392/FL_AMP_articles/Mobile_sticky_banner',
    sizes: [320, 50],
    id: 'div-gpt-ad-Mobile_sticky_banner-320x50'
  }
}

export const DFP_BANNER_MOCKED = {
  MOCKED_320X50: {
    path: '',
    sizes: [320, 50],
    id: ''
  },
  MOCKED_728X90: {
    path: '',
    sizes: [728, 90],
    id: ''
  },
  MOCKED_970X90: {
    path: '',
    sizes: [970, 90],
    id: ''
  },
  MOCKED_120x600: {
    path: '',
    sizes: [120, 600],
    id: ''
  },
  MOCKED_160x600: {
    path: '',
    sizes: [160, 600],
    id: ''
  },
  MOCKED_300x600: {
    path: '',
    sizes: [300, 600],
    id: ''
  },
  MOCKED_300x250: {
    path: '',
    sizes: [300, 250],
    id: ''
  },
  MOCKED_180x150: {
    path: '',
    sizes: [180, 150],
    id: ''
  }
}
