<template>
  <header class="header" :id="$options.consts.HEADER_ID">
    <i
      v-observe-visibility="visibilityOptions"
      class="header__top-position-detector"
    />
    <div class="header__background-wrapper">
      <h2 class="header__preview-mode-panel title">Preview Mode</h2>

      <a-visibility hide :to="$breakpoint.tablet" :keep-on-client="true">
        <a-header-desktop
          :navigation-items="navigationItems"
          class="desktop-header__wrapper"
          @mounted="onDesktopHeaderMounted"
        />
      </a-visibility>

      <a-visibility
        show
        :to="$breakpoint.tablet"
        :keep-on-client="true"
        @visibility-change="onMobileHeaderVisibilityChange"
      >
        <a-header-mobile
          :navigation-items="navigationItems"
          @mounted="onMobileHeaderMounted"
        />
      </a-visibility>

      <a-progress-bar />
    </div>

    <a-ci-commit-tag />
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { HEADER_ITEM_OPTIONS_BY_NAME } from '@/components/AHeader/enums'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import { HEADER_ID } from 'enums/header'
import AProgressBar from '@/components/_layout/AProgressBar'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
import { CLOSE_MOBILE_HEADER_MENU_EVENT } from '@/components/AHeader/AHeaderMobile/enums'

export default {
  name: 'AHeader',
  mixins: [serverCacheKey],
  components: {
    AHeaderMobile: hydrateWhenVisible(
      () => import('@/components/AHeader/AHeaderMobile'),
      { props: ['navigation-items'] }
    ),
    AHeaderDesktop: hydrateWhenVisible(
      () => import('@/components/AHeader/AHeaderDesktop'),
      { props: ['navigation-items'] }
    ),
    ACiCommitTag: hydrateWhenVisible(() => import('./ACiCommitTag')),
    AProgressBar
  },
  props: {
    isDesktopHeaderMounted: propValidator([PROP_TYPES.BOOLEAN], false),
    isMobileHeaderMounted: propValidator([PROP_TYPES.BOOLEAN], false)
  },
  consts: {
    HEADER_ID
  },
  data() {
    return {
      visibilityOptions: {
        callback: this.changeIsHeaderAtTop
      }
    }
  },
  computed: {
    ...mapGetters({
      headerMenuItems: 'headerMenuItems',
      isPreviewMode: 'isPreviewMode'
    }),
    navigationItems() {
      if (!this.headerMenuItems) return []

      return this.headerMenuItems.map(item => {
        const itemOptions =
          HEADER_ITEM_OPTIONS_BY_NAME[item.Name.toLowerCase()] || {}

        return {
          ...item,
          ...itemOptions
        }
      })
    }
  },
  methods: {
    ...mapActions({
      setHeaderAtTopState: 'setHeaderAtTopState'
    }),
    changeIsHeaderAtTop(isDetectorVisible) {
      this.setHeaderAtTopState(!isDetectorVisible)
    },
    onDesktopHeaderMounted() {
      this.$emit('update:is-desktop-header-mounted', true)
    },
    onMobileHeaderMounted() {
      this.$emit('update:is-mobile-header-mounted', true)
    },
    onMobileHeaderVisibilityChange({ isVisible }) {
      if (!process.client) return

      if (!isVisible) {
        /**
         * We purposefully close the expanded menu when layout changes
         * to desktop. This is done to prevent bugs when scroll is
         * locked on desktop after rotating a device.
         */
        this.$bus.$emit(CLOSE_MOBILE_HEADER_MENU_EVENT)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  height: $size-header-expanded;
  z-index: $z-index-header;

  @include tablet {
    height: $mobile-header-height;
  }

  .header__top-position-detector {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    visibility: hidden;
  }

  .header__preview-mode-panel {
    width: 100%;
    height: 0;
    overflow: hidden;
    background: $c--light-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.7s;
  }

  .header__background-wrapper {
    position: relative;
    pointer-events: all;
    background: $c--header;
  }

  @include all-browser-except-safari {
    &.nofit {
      width: calc(100% - 17px);
    }
  }

  @include mobile {
    &.nofit {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
@import '~assets/scss/local/mixins/maps';

#__nuxt {
  .global-wrapper.preview-mode .header__preview-mode-panel {
    height: $size-preview-panel;
  }

  .global-wrapper.preview-mode .header {
    height: $size-header-expanded + $size-preview-panel;
  }
}
</style>
