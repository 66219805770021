import api from '~/utils/api'
import * as types from '~/store/mutation-types'
import { cachedRequest } from '@/utils/jobRequestApiMethods'
import { API_VERSION } from 'enums/api'

export const state = () => ({
  topBrokers: []
})

export const getters = {
  topBrokers: state => state.topBrokers
}

export const actions = {
  async requestTermsOfUse() {
    try {
      const { data } = await this.$axios.get(api.layout.getTermsOfUse())
      return data
    } catch (err) {
      throw err
    }
  },
  async requestPrivacyNotice() {
    try {
      const { data } = await this.$axios.get(api.layout.getPrivacyNotice())
      return data
    } catch (err) {
      throw err
    }
  },
  async requestTopBrokers({ commit }) {
    try {
      const {
        data: { TopBrokers }
      } = await cachedRequest({
        method: this.$axios.get,
        args: [api.layout.getTopBrokers()],
        apiVersion: API_VERSION.V1
      })

      commit(types.SET_TOP_BROKERS, TopBrokers)
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_TOP_BROKERS](state, value) {
    state.topBrokers = value
  }
}
