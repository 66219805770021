import { guid } from '../../../server/helper'

// These enums must be synced with relevant SCSS variables
export const ADBLOCKER_ELEMENT_PLACEHOLDER = {
  BANNER_SPACE_ALLOCATOR: 'guid-5bbd7ec0',
  MOBILE_MODIFIER: 'guid-d92de3ab',
  TABLET_MODIFIER: 'guid-b3fe1ab7',
  DESKTOP_SM_MODIFIER: 'guid-261b93fa',
  DESKTOP_SM2_MODIFIER: 'guid-69d83328',
  DESKTOP_SM3_MODIFIER: 'guid-0a152770',
  DESKTOP_MD_MODIFIER: 'guid-e8960073',
  DESKTOP_LG_MODIFIER: 'guid-4cd34ff4',
  DESKTOP_XL_MODIFIER: 'guid-1c68ec4d',
  ABOVE_DESKTOP_XL_MODIFIER: 'guid-300a6529',
  SPONSORED_ARTICLE_INDICATOR: 'guid-5fb61462'
}

const ADBLOCKER_ELEMENT_DYNAMIC_VALUE_TEMPLATE = {
  [ADBLOCKER_ELEMENT_PLACEHOLDER.BANNER_SPACE_ALLOCATOR]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.MOBILE_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.TABLET_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM2_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM3_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_MD_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_LG_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_XL_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.ABOVE_DESKTOP_XL_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.SPONSORED_ARTICLE_INDICATOR]: null
}

function generateUniqueId(existingIds = []) {
  let id
  do {
    id = `guid-${guid().match(/[\d\w]*/)[0]}`
  } while (existingIds.includes(id))

  return id
}

function fillDynamicValues(obj) {
  const usedIds = []
  const resultObject = {}

  for (const key in obj) {
    const uniqueId = generateUniqueId(usedIds)
    resultObject[key] = uniqueId
    usedIds.push(uniqueId)
  }

  return resultObject
}

export const ADBLOCKER_ELEMENT_DYNAMIC_VALUE_BY_PLACEHOLDER = fillDynamicValues(
  ADBLOCKER_ELEMENT_DYNAMIC_VALUE_TEMPLATE
)
