/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning-icon': {
    width: 12,
    height: 11,
    viewBox: '0 0 12 11',
    data:
      '<g opacity=".6" _fill="#fff"><path pid="0" d="M6.5 4V3h-1v1h1zM6.5 8V4.5h-1V8h1z"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M1 5.5c0-2.75 2.25-5 5-5s5 2.25 5 5-2.25 5-5 5-5-2.25-5-5zm9.1 0c0-2.25-1.85-4.1-4.1-4.1-2.25 0-4.1 1.85-4.1 4.1 0 2.25 1.85 4.1 4.1 4.1 2.25 0 4.1-1.85 4.1-4.1z"/></g>'
  }
})
