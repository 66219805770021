/**
 * @desc Store mutations list
 */

// General
export const SET_MAIN_SPINNER = 'SET_MAIN_SPINNER'
export const SET_PAGE_NAVIGATION_IN_PROGRESS_STATE =
  'SET_PAGE_NAVIGATION_IN_PROGRESS_STATE'
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH'
export const SET_WINDOW_HEIGHT = 'SET_WINDOW_HEIGHT'
export const SET_IS_SCROLLBAR_HIDDEN = 'SET_IS_SCROLLBAR_HIDDEN'
export const SET_MOBILE_BOTTOM_PADDING = 'SET_MOBILE_BOTTOM_PADDING'
export const SET_SCROLL_TOP = 'SET_SCROLL_TOP'
export const SET_HEADER_AT_TOP_STATE = 'SET_HEADER_AT_TOP_STATE'
export const SET_SCROLL_TOP_ON_HIDE_SCROLL = 'SET_SCROLL_TOP_ON_HIDE_SCROLL'
export const SET_INITIAL_DEVICE = 'SET_INITIAL_DEVICE'
export const SET_SPINNER_STATUS = 'SET_SPINNER_STATUS'
export const SET_USER_AGENT = 'SET_USER_AGENT'
export const SET_ROUTER_NAVIGATION_DETAILS = 'SET_ROUTER_NAVIGATION_DETAILS'
export const SET_HEADER_ITEMS = 'SET_HEADER_ITEMS'
export const SET_FOOTER_ITEMS = 'SET_FOOTER_ITEMS'
export const SET_STAY_CONNECTED = 'SET_STAY_CONNECTED'
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE'
export const SET_CHAR_SIZE = 'SET_CHAR_SIZE'
export const SET_DOCUMENT_VISIBILITY_STATE = 'SET_DOCUMENT_VISIBILITY_STATE'
export const SET_IS_CURRENT_USER_BOT = 'SET_IS_CURRENT_USER_BOT'
export const SET_PREVIEW_MODE = 'SET_PREVIEW_MODE'
export const SET_CAPTCHA_LOAD_STATUS = 'SET_CAPTCHA_LOAD_STATUS'
export const SET_IS_ONLINE_STATE = 'SET_IS_ONLINE_STATE'
export const SET_CAPTCHA_LOADING_IN_PROGRESS_STATUS =
  'SET_CAPTCHA_LOADING_IN_PROGRESS_STATUS'
export const SET_DISQUS_WIDGET_LOAD_STATUS = 'SET_DISQUS_WIDGET_LOAD_STATUS'
export const SET_USER_AGENT_DETAILS = 'SET_USER_AGENT_DETAILS'
export const SET_STICKY_WIDGETS_AS_NOT_PENDING =
  'SET_STICKY_WIDGETS_AS_NOT_PENDING'

// Auth
export const SET_AUTH_TOKENS = 'SET_AUTH_TOKENS'
export const LOG_OUT_USER = 'LOG_OUT_USER'
export const SET_LOGIN_BAN_DETAILS = 'SET_LOGIN_BAN_DETAILS'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS'
export const UPDATE_WAS_ONBOARDING_FLOW_SHOWN_VALUE =
  'UPDATE_WAS_ONBOARDING_FLOW_SHOWN_VALUE'
export const SET_AUTH_FORM_LOADING_IN_PROGRESS =
  'SET_AUTH_FORM_LOADING_IN_PROGRESS'
export const SET_AUTH_FORM_LOADED = 'SET_AUTH_FORM_LOADED'
export const REMOVE_REFRESH_TOKEN = 'REMOVE_REFRESH_TOKEN'
export const SET_IS_AUTH_STATUS_REQUEST_IN_PROGRESS =
  'SET_IS_AUTH_STATUS_REQUEST_IN_PROGRESS'
export const SET_IS_AUTH_STATUS_REQUESTED = 'SET_IS_AUTH_STATUS_REQUESTED'

// Global search
export const SET_SKIP_SEARCH_TRACK_ON_SEARCH_RESULTS_PAGE_STATUS =
  'SET_SKIP_SEARCH_TRACK_ON_SEARCH_RESULTS_PAGE_STATUS'

// Seo
export const SET_PAGE_SEO = 'SET_PAGE_SEO'
export const SET_ERROR_PAGE_SEO = 'SET_ERROR_PAGE_SEO'
export const SET_HOMEPAGE_SEO = 'SET_HOMEPAGE_SEO'
export const SET_OG_SITE_NAME = 'SET_OG_SITE_NAME'

// Sticky banners
export const SET_FOOTER_VISIBILITY_STATE = 'SET_FOOTER_VISIBILITY_STATE'

// Leads
export const SET_WIDGETS_SETTINGS = 'SET_WIDGETS_SETTINGS'

// Breadcrumbs
export const SET_DYNAMIC_PAGES_BREADCRUMBS = 'SET_DYNAMIC_PAGES_BREADCRUMBS'

// Google DFP Banners
export const SET_GPT_LOAD_STARTED_STATUS = 'SET_GPT_LOAD_STARTED_STATUS'
export const SET_GPT_LOADED_STATUS = 'SET_GPT_LOADED_STATUS'
export const SET_BANNER_INIT_STATUS = 'SET_BANNER_INIT_STATUS'
export const SET_BANNER_SLOT = 'SET_BANNER_SLOT'
export const SET_ACTIVE_BANNER_SLOT = 'SET_ACTIVE_BANNER_SLOT'
export const REMOVE_BANNER_SLOT = 'REMOVE_BANNER_SLOT'
export const REMOVE_ACTIVE_BANNER_SLOT = 'REMOVE_ACTIVE_BANNER_SLOT'
export const SET_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN =
  'SET_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN'
export const DELETE_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN =
  'DELETE_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN'

// Comments (Disqus)
export const SET_DISQUS_SSO_TOKEN = 'SET_DISQUS_SSO_TOKEN'

// Global Spinner for page loading
export const SET_PAGE_LOADING_STATUS = 'SET_PAGE_LOADING_STATUS'
export const DISABLE_SOFT_RENDER = 'DISABLE_SOFT_RENDER'

// Errors
export const ADD_ERROR_MESSAGE_TO_QUEUE = 'ADD_ERROR_MESSAGE_TO_QUEUE'
export const REMOVE_ERROR_MESSAGE_FROM_QUEUE = 'REMOVE_ERROR_MESSAGE_FROM_QUEUE'
export const CLEAR_ERROR_MESSAGE_QUEUE = 'CLEAR_ERROR_MESSAGE_QUEUE'
export const SET_CURRENT_PAGE_AS_410 = 'SET_CURRENT_PAGE_AS_410'
export const SET_CURRENT_PAGE_AS_NOT_410 = 'SET_CURRENT_PAGE_AS_NOT_410'
export const SAVE_VUE_FILES_VALIDATION_RESULTS =
  'SAVE_VUE_FILES_VALIDATION_RESULTS'

// Notifications
export const SET_NEW_NOTIFICATION_COUNT = 'SET_NEW_NOTIFICATION_COUNT'

// Sidebar
export const SET_MUST_READ_ITEMS = 'SET_MUST_READ_ITEMS'
export const IS_NEXT_REQUEST_SHOULD_BE_SKIPPED =
  'IS_NEXT_REQUEST_SHOULD_BE_SKIPPED'

// Forex tools
export const SET_FOREX_TOOLS_BUTTONS_SETTINGS =
  'SET_FOREX_TOOLS_BUTTONS_SETTINGS'

// Ebook
export const SET_EBOOK = 'SET_EBOOK'
export const SET_EBOOK_REQUEST_STARTED = 'SET_EBOOK_REQUEST_STARTED'
export const SET_EBOOK_REQUEST_FINISHED = 'SET_EBOOK_REQUEST_FINISHED'

/** Third parties **/
export const SET_FACEBOOK_API_INITIALIZED = 'SET_FACEBOOK_API_INITIALIZED'

// Onboarding
export const SET_IS_ARTICLE_ONBOARDING_IN_PROGRESS =
  'SET_IS_ARTICLE_ONBOARDING_IN_PROGRESS'
export const SET_IS_ALL_NEWS_ONBOARDING_IN_PROGRESS =
  'SET_IS_ALL_NEWS_ONBOARDING_IN_PROGRESS'

// OneTrust
export const SET_OPTANON_CONSENT_ACTIVE_GROUPS_FROM_COOKIE =
  'SET_OPTANON_CONSENT_ACTIVE_GROUPS_FROM_COOKIE'
export const UPDATE_OPTANON_CONSENT_ACTIVE_GROUPS =
  'UPDATE_OPTANON_CONSENT_ACTIVE_GROUPS'
export const REGISTER_HANDLER_TO_EXECUTE_ON_CONSENT_CHANGE =
  'REGISTER_HANDLER_TO_EXECUTE_ON_CONSENT_CHANGE'
export const UPDATE_HANDLERS_TO_EXECUTE_ON_CONSENT_CHANGE =
  'UPDATE_HANDLERS_TO_EXECUTE_ON_CONSENT_CHANGE'
export const REMOVE_LOCAL_HANDLERS_ON_ROUTE_CHANGE =
  'REMOVE_LOCAL_HANDLERS_ON_ROUTE_CHANGE'
export const REMOVE_HANDLERS_FOR_ENTITY_TYPE = 'REMOVE_HANDLERS_FOR_ENTITY_TYPE'
export const UPDATE_OPTANON_ALERT_BOX_IS_CLOSED_STATE =
  'UPDATE_OPTANON_ALERT_BOX_IS_CLOSED_STATE'

// Modal
export const ADD_MODAL_NAME_TO_OPENED = 'ADD_MODAL_NAME_TO_OPENED'
export const REMOVE_MODAL_NAME_FROM_OPENED = 'REMOVE_MODAL_NAME_FROM_OPENED'

// Router
export const SET_CHANGE_ROUTE_REQUEST_PROMISE =
  'SET_CHANGE_ROUTE_REQUEST_PROMISE'
export const SET_CHANGE_ROUTE_REQUEST_RESOLVER =
  'SET_CHANGE_ROUTE_REQUEST_RESOLVER'
export const ADD_ROUTE_TO_QUEUE = 'ADD_ROUTE_TO_QUEUE'
export const REMOVE_ROUTE_FROM_QUEUE = 'REMOVE_ROUTE_FROM_QUEUE'

// layout
export const SET_TOP_BROKERS = 'SET_TOP_BROKERS'
